

export const Social = ({
    insta, be
}) => {
    return (
        <ul className="burger__social">
            <li>
                <a className="burger__social-link" href="https://www.instagram.com/alpairya/" target="__blank">
                    <img src={insta} alt="Insta" />
                </a>
            </li>
            <li>
                <a className="burger__social-link" href="https://www.behance.net/alpairya" target="__blank">
                    <img src={be} alt="Be" />
                </a>
            </li>
        </ul>
    )
}