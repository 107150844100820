import { useEffect } from "react";

export const useClickOutside = (ref, stateFn) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                stateFn(false)
            }
        }
    
        const handleMouseDown = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                stateFn(false)
            }
        }
    
        document.addEventListener("click", handleClickOutside)
        document.addEventListener("mousedown", handleMouseDown)
    
        return () => {
          document.removeEventListener("click", handleClickOutside)
          document.removeEventListener("mousedown", handleMouseDown)
        }
    }, [ref, stateFn])
}