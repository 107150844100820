import React, { useEffect, useState } from "react";
import "./Footer.scss";
import logo from "../../img/logo.svg";
import circle from "../../img/circle.svg";
import insta from "../../img/insta.png";
import be from "../../img/Be.png";
import wave from "../../img/footer-img.svg";
import { Logo } from "../Header/burger/Logo";

export function Footer() {
  const scrollToElement = (element) => {
    window.scroll({
      behavior: "smooth",
      top: element.offsetTop,
    });
  };

  const handleClick = (e, elementId) => {
    e.preventDefault();
    const element = document.getElementById(elementId);
    scrollToElement(element);
  };

  const [currentYear, setCurrentYear] = useState(null);

  useEffect(() => {
    const year = new Date().getFullYear();
    setCurrentYear(year);
  }, []);

  return (
    <footer className="footer">
      <div className="container">
        <Logo logo={logo}/>
        <div className="footer__container">
          <nav className="footer__nav">
            <ul className="footer__list">
              <li>
                <a href="#services" onClick={(e) => handleClick(e, "services")}>
                  services
                </a>
              </li>
              <li>
                <a href="#projects" onClick={(e) => handleClick(e, "projects")}>
                  projects
                </a>
              </li>
              <li>
                <a href="#about" onClick={(e) => handleClick(e, "about")}>
                  about
                </a>
              </li>
            </ul>
          </nav>
          <div className="footer__center">
            <div className="footer-hero__branding">
              <img className="footer__circle" src={circle} alt="" />
              {/* <img className="footer__logo" src={logo} alt="Alpairya" /> */}
              <span>A</span>
            </div>
            <span className="footer__copyright">
              Alpairya - Web development studio © {currentYear}
            </span>
          </div>
          <div className="footer__right">
            <ul className="footer__right-list">
              <li>
                <a href={"mailto:info@alpairya.by"} className="footer__link">
                  info@alpairya.by
                </a>
              </li>
              <li>
                <a href={"tel:+375293401529"} className="footer__link">
                  +375(29)340-15-29
                </a>
              </li>
            </ul>
            <ul className="footer__social">
              <li>
                <a
                  className="footer__social-link"
                  href="https://www.instagram.com/alpairya/"
                  target="__blank"
                >
                  <img src={insta} alt="Insta" />
                </a>
              </li>
              <li>
                <a
                  className="footer__social-link"
                  href="https://www.behance.net/alpairya"
                  target="__blank"
                >
                  <img src={be} alt="Be" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer__copyright-wrapp">
          <span className="footer__copyright">
            Alpairya - Web development studio © {currentYear}
          </span>
        </div>
      </div>
      <div className="footer__wave-wrapp">
        <img src={wave} alt="" />
      </div>
    </footer>
  );
}
