import { useState, useRef } from 'react';
import { useClickOutside } from '../hooks/useClickOutside';
import "./Dropdown.scss";

export const Dropdown = ({ options, selectedOption, onSelect, name, onChange, onBlur }) => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef(null)

    const handleSelect = (option) => {
        setIsOpen(false)
        onSelect(option)
        onChange && onChange(name, option.value)
    }

    useClickOutside(dropdownRef, setIsOpen)

    return (
        <div className="dropdown" ref={dropdownRef}>
            <div
                tabIndex="0"
                className={isOpen ? "dropdown__btn active-btn" : "dropdown__btn"}
                onClick={() => setIsOpen(!isOpen)}
                onBlur={() => onBlur && onBlur(name)}
            >
                {selectedOption && selectedOption.flag}
                <span className="dropdown__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M5 7.5L10 12.5L15 7.5" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            </div>
            {isOpen && (
                <div className="dropdown__content-wrapper">
                    <ul className="dropdown__content">
                        {options.map((option) => (
                            <li
                                key={option.value}
                                className={option.value === selectedOption.value ? 'dropdown__item selected' : 'dropdown__item'}
                                onClick={() => handleSelect(option)}
                            >
                                {option.label}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}