import { useState, useEffect } from "react";
import { useForm } from "../../hooks/useForm";
import { Text } from "./Text";
import { Input } from "../../UI/Input";
import { Dropdown } from "../../UI/Dropdown";
// import { Textarea } from "../../UI/Textarea";
import { Button } from "../../UI/Button";
import { Image } from "../../UI/Image";
import { Close } from "./Close";
import play from "../../img/play.svg"
// import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
// import { PhoneInputMask } from "../phone-mask/PhoneInputMask";
import { SuccessMessage } from "../common/SuccessMessage";
import { FailMessage } from "../common/FailMessage";
import { postForm } from "./api";
// import { mask } from "../phone-mask/mask";
// import { useMaskRU } from "../phone-mask/useMaskRU";
// import { useMaskBY } from "../phone-mask/useMaskBY";
// import { useMaskBY1 } from "../phone-mask/useMaskBY1";
// import { useMaskRU1 } from "../phone-mask/useMaskRU1";
import { useMask } from "../phone-mask/useMask";
import { PhoneInputMask } from "../phone-mask/PhoneInputMask";
import { FileInput } from "../../UI/FileInput";
import "./Form.scss";

export const Form = ({ handleClose }) => {

    const {
        formData,
        error,
        setError,
        handleSelect,
        handleChange,
        handleChangePhone,
        handleChangeEmail,
        status,
        setStatus,
        countryCodes,
        inputPhone,
        setInputPhone,
        validateEmpty,
        validateEmail
    } = useForm()

    console.log(formData)

    const [inputCode, setInputCode] = useState(formData.country_code.value)
    const [country, setCountry] = useState(formData.country_code.flag)
    const [maxLength, setMaxLength] = useState(formData.country_code.maxLength)

    useEffect(() => {
        setInputCode(formData.country_code.value)
        setCountry(formData.country_code.flag)
        setMaxLength(formData.country_code.maxLength)
    }, [formData.country_code.value, formData.country_code.flag, formData.country_code.maxLength])

    const { onPhoneInput } = useMask(country, inputCode, setInputPhone)

    const handleSubmit = (event) => {
        event.preventDefault()

        // let isValid = true

        // if (!formData.name || !formData.email || !formData.phone) {
        //     isValid = false
        // }

        // if (isValid) {
            const formElements = new FormData(event.target)

            postForm(formElements)
            .then(res => {
                console.log(res)
                if (res.status === 200) {
                    handleClose()
                    setStatus(1)
                    setTimeout(() => {
                        setStatus(0)
                    }, 1200)
                }
            }).catch(res => {
                if (res.status !== 200) {
                    setStatus(2)
                    setTimeout(() => {
                        setStatus(0)
                    }, 2400)
                }
                return
            })
        // }
    }

    return (
        <form
            className="form"
            onSubmit={handleSubmit}
        >
            <Close className={'form__close'} onClick={handleClose}/>
            <Text className={'form__title'} text={'Leave your contacts and we will contact you soon'} />

            <div className="form__field">
                {/* <Text className={'form__field-title'} text={'Your name'} /> */}
                <Input
                    name={'name'}
                    className={'form__input'}
                    type={'text'}
                    placeholder={'Your name'}
                    value={formData.name}
                    onChange={e => handleChange('name', e.target.value)}
                    required
                />
                {error.name &&
                    <p className="form__error-text">{error.name}</p>
                }
            </div>

            <div className="form__field">
                {/* <Text className={'form__field-title'} text={'Your e-mail'} /> */}
                <Input
                    name={'email'}
                    className={'form__input'}
                    type={'email'}
                    placeholder={'Your e-mail'}
                    value={formData.email}
                    onChange={e => handleChangeEmail('email', e.target.value)}
                    required
                />
                {error.email &&
                    <p className="form__error-text">{error.email}</p>
                }
            </div>

            {/* <div className="form__field">
                <Input
                    name={'phone'}
                    className={'form__input'}
                    type={'tel'}
                    placeholder={'Your phone by'}
                    onInput={mask}
                    onFocus={mask}
                    onBlur={mask}
                    onKeyDown={mask}
                    onChange={e => handleChangePhone('phone', e.target.value)}
                />
                {error.phone &&
                    <p className="form__error-text">{error.phone}</p>
                } */}
                
                {/* <PhoneInput
                    name={'phone'}
                    country={'fr'}
                    value={formData.phone}
                    onChange={phone => handleChangePhone('phone', phone)}
                    onlyCountries={['at', 'de', 'fr', 'ru', 'by', 'ge', 'pl']}
                    // masks={{fr: '(...) ..-..-..', at: '(....) ...-....'}}
                    inputProps={{
                        required: true
                    }}
                    enableSearch={true}
                />
                {error.phone &&
                    <p className="form__error-text">{error.phone}</p>
                } */}
            {/* </div> */}

            <div className="form__field">
                <div className="form__phone">
                    <PhoneInputMask
                        name={'phone'}
                        className={'form__input'}
                        maxLength={maxLength}
                        value={inputCode + inputPhone}
                        onInput={onPhoneInput}
                        onChange={handleChangePhone}
                        codeName={'country_code'}
                        options={countryCodes}
                        selectedOption={formData.country_code}
                        onSelect={handleSelect}
                    />
                </div>
                {error.phone &&
                    <p className="form__error-text">{error.phone}</p>
                }
            </div>

            {/* <div className="form__field">
                <Input
                    name={'phone'}
                    className={'form__input'}
                    type={'tel'}
                    placeholder={'Your phone ru'}
                    onInput={onPhoneInputRU}
                    onPaste={onPhonePasteRU}
                    onKeyDown={onPhoneKeyDownRU}
                    onChange={e => handleChangePhone('phone_ru', e.target.value)}
                />
            </div> */}

            <div className="form__field">
                {/* <Text className={'form__field-title'} text={'Your message'} /> */}
                {/* <Textarea
                    name={'message'}
                    className={'form__input'}
                    placeholder={'Message text'}
                    value={formData.message}
                    onChange={e => handleChange('message', e.target.value)}
                    rows={4}
                /> */}
                {/* <input name="attachment" type="file" /> */}
                <FileInput
                    name="attachment"
                />
            </div>

            <div className="form__button-wrapper">
                <Button
                    className="form__button"
                    type="submit"
                    disabled={!formData.name || !formData.email || !formData.phone ? true : false}
                >
                    <Text className={'form__button-text'} text={'Send'} />
                    <Image className={'form__button-img'} src={play} alt={'button'} />
                </Button>
            </div>
            {status === 1 &&
                <SuccessMessage message={"Saved successfully"} />
            }
            {status === 2 &&
                <FailMessage message={"Something went wrong"} />
            }
        </form>
    )
}