import "./Modal.scss";

export const Modal = ({ isOpen, setIsOpen, children }) => {

    return (
        <div className={isOpen ? "modal active" : "modal"} onClick={() => setIsOpen(false)}>
            <div className="modal__content-wrapper" onClick={event => event.stopPropagation()}>
                <div className="modal__content">
                    {children}
                </div>
            </div>
        </div>
    );
};