import { useState } from 'react';
// import { useSelector } from 'react-redux';
// import { Icons } from '../sprite/Icons';
import "./FileInput.scss";

export const FileInput = ({
    name,
    className,
    important,
    // fileName,
    // setFileName,
    handleSelect,
    onChange,
    onBlur,
    multiple
}) => {

    // const {language} = useSelector(state => state.language)

    const [inputKey, setInputKey] = useState(Date.now())

    const [fileName, setFileName] = useState('')

    const handleFileChange = (event) => {
        const files = event.target.files

        // handleSelect(event)
        // onChange(event)

        if (files.length > 0) {
            const fileName = files[0].name
            const extension = fileName.split('.').pop()
            const maxLength = 17
            const truncatedName = fileName.slice(0, maxLength - extension.length - 1)
            if (fileName.length <= maxLength) {
                setFileName(fileName)
            } else {
                setFileName(`${truncatedName}...${extension}`)
            }
        } else {
            setFileName('')
        }
        setInputKey(Date.now())
    }

  return (
    <div className="input__wrapper">
        <input
            key={inputKey}
            name={name}
            type="file"
            id="input__file"
            className={`input input__file`}
            onChange={handleFileChange}
            // onBlur={onBlur}
            multiple={multiple}
        />
        <label htmlFor="input__file" className={`input__file-button ${className}`}>
            <span className="input__file-icon-wrapper">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8M14 2L20 8M14 2V8H20M12 18V12M9 15H15" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </span>
            <span className="input__file-button-text">Browse file</span>
        </label>
        <div className={'input__file-text-container'}>
            {fileName && (
                <div className="input__file-text-wrapper">
                    <span className="input__file-text">{fileName}</span>
                    {/* <Icons className={"icon-small input__file-icon-inside"} id={"check-circle"} /> */}
                </div>
            )}
        </div>
    </div>
  );
}