

export const by = (inputNumbersValue) => {

    let formattedValue = ''

    if (inputNumbersValue.length > 3) {
        formattedValue += " (" + inputNumbersValue.substring(3, 5)
    }
    if (inputNumbersValue.length > 5) {
        formattedValue += ") " + inputNumbersValue.substring(5, 8)
    }
    if (inputNumbersValue.length > 8) {
        formattedValue += "-" + inputNumbersValue.substring(8, 10)
    }
    if (inputNumbersValue.length > 10) {
        formattedValue += "-" + inputNumbersValue.substring(10, 12)
    }

    return formattedValue
}