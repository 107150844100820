import React from "react";
import { TabsSection } from "../components/TabsSection/TabsSection";
import { Projects } from "../components/Projects/Projects";
import { About } from "../components/About/About";

export const HomePages = () => {
    return (
        <>
        <TabsSection/>
        <Projects/>
        <About/>
        </>
    )
}