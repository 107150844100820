import { useState } from "react";

export const useTab = () => {

    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    return { activeTab, handleTabClick }
}