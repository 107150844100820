

export const ButtonClose = ({
    handleClose
}) => {
    return (
        <button className="burger__button" onClick={handleClose}>
            <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_86_42)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M30 56.1022C44.0192 56.1022 55.3846 44.7368 55.3846 30.7176C55.3846 16.6984 44.0192 5.33297 30 5.33297C15.9808 5.33297 4.61538 16.6984 4.61538 30.7176C4.61538 44.7368 15.9808 56.1022 30 56.1022ZM30 60.7176C46.5692 60.7176 60 47.2868 60 30.7176C60 14.1484 46.5692 0.71759 30 0.71759C13.4308 0.71759 0 14.1484 0 30.7176C0 47.2868 13.4308 60.7176 30 60.7176Z" fill="#2F2A4F"/>
                    <path d="M18.6709 19.6709C19.1006 19.2413 19.6834 19 20.291 19C20.8986 19 21.4814 19.2413 21.9111 19.6709L30.0138 27.7737L38.1166 19.6709C38.5488 19.2535 39.1276 19.0225 39.7284 19.0278C40.3292 19.033 40.904 19.274 41.3288 19.6988C41.7537 20.1237 41.9947 20.6984 41.9999 21.2993C42.0051 21.9001 41.7742 22.4789 41.3567 22.9111L33.254 31.0138L41.3567 39.1166C41.7742 39.5488 42.0051 40.1276 41.9999 40.7284C41.9947 41.3292 41.7537 41.904 41.3288 42.3288C40.904 42.7537 40.3292 42.9947 39.7284 42.9999C39.1276 43.0051 38.5488 42.7742 38.1166 42.3567L30.0138 34.254L21.9111 42.3567C21.4789 42.7742 20.9001 43.0051 20.2993 42.9999C19.6984 42.9947 19.1237 42.7537 18.6988 42.3288C18.274 41.904 18.033 41.3292 18.0278 40.7284C18.0225 40.1276 18.2535 39.5488 18.6709 39.1166L26.7737 31.0138L18.6709 22.9111C18.2413 22.4814 18 21.8986 18 21.291C18 20.6834 18.2413 20.1006 18.6709 19.6709Z" fill="#2F2A4F"/>
                </g>
                <defs>
                    <clipPath id="clip0_86_42">
                        <rect width="60" height="60" fill="white" transform="translate(0 0.71759)"/>
                    </clipPath>
                </defs>
            </svg>
        </button>
    )
}