

export const ButtonOpen = ({
    handleOpen
}) => {
    return (
        <button className="burger__button" onClick={handleOpen}>
            <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2_7)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M11.5385 26.1022C11.5385 25.4902 11.7816 24.9032 12.2144 24.4704C12.6472 24.0377 13.2341 23.7945 13.8462 23.7945H37.6339C38.2459 23.7945 38.8329 24.0377 39.2656 24.4704C39.6984 24.9032 39.9415 25.4902 39.9415 26.1022C39.9415 26.7143 39.6984 27.3012 39.2656 27.734C38.8329 28.1668 38.2459 28.4099 37.6339 28.4099H13.8462C13.2341 28.4099 12.6472 28.1668 12.2144 27.734C11.7816 27.3012 11.5385 26.7143 11.5385 26.1022ZM11.5385 16.8714C11.5385 16.2594 11.7816 15.6724 12.2144 15.2397C12.6472 14.8069 13.2341 14.5638 13.8462 14.5638H46.1539C46.7659 14.5638 47.3529 14.8069 47.7856 15.2397C48.2184 15.6724 48.4615 16.2594 48.4615 16.8714C48.4615 17.4835 48.2184 18.0705 47.7856 18.5032C47.3529 18.936 46.7659 19.1791 46.1539 19.1791H13.8462C13.2341 19.1791 12.6472 18.936 12.2144 18.5032C11.7816 18.0705 11.5385 17.4835 11.5385 16.8714ZM11.5385 35.333C11.5385 34.7209 11.7816 34.134 12.2144 33.7012C12.6472 33.2684 13.2341 33.0253 13.8462 33.0253H46.1539C46.7659 33.0253 47.3529 33.2684 47.7856 33.7012C48.2184 34.134 48.4615 34.7209 48.4615 35.333C48.4615 35.945 48.2184 36.532 47.7856 36.9648C47.3529 37.3975 46.7659 37.6407 46.1539 37.6407H13.8462C13.2341 37.6407 12.6472 37.3975 12.2144 36.9648C11.7816 36.532 11.5385 35.945 11.5385 35.333ZM11.5385 44.5638C11.5385 43.9517 11.7816 43.3647 12.2144 42.932C12.6472 42.4992 13.2341 42.2561 13.8462 42.2561H37.6339C38.2459 42.2561 38.8329 42.4992 39.2656 42.932C39.6984 43.3647 39.9415 43.9517 39.9415 44.5638C39.9415 45.1758 39.6984 45.7628 39.2656 46.1955C38.8329 46.6283 38.2459 46.8714 37.6339 46.8714H13.8462C13.2341 46.8714 12.6472 46.6283 12.2144 46.1955C11.7816 45.7628 11.5385 45.1758 11.5385 44.5638Z" fill="#2F2A4F"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M30 56.1022C44.0192 56.1022 55.3846 44.7368 55.3846 30.7176C55.3846 16.6984 44.0192 5.33297 30 5.33297C15.9808 5.33297 4.61538 16.6984 4.61538 30.7176C4.61538 44.7368 15.9808 56.1022 30 56.1022ZM30 60.7176C46.5692 60.7176 60 47.2868 60 30.7176C60 14.1484 46.5692 0.71759 30 0.71759C13.4308 0.71759 0 14.1484 0 30.7176C0 47.2868 13.4308 60.7176 30 60.7176Z" fill="#2F2A4F"/>
                </g>
                <defs>
                    <clipPath id="clip0_2_7">
                        <rect width="60" height="60" fill="white" transform="translate(0 0.71759)"/>
                    </clipPath>
                </defs>
            </svg>
        </button>
    )
}