import { CardLink } from "./CardLink";
import { CardDescription } from "./CardDescription";
import "./Card.scss";

export const Card = ({
    link,
    text,
    description,
    children
}) => {
    return (
        <div className="project-card">
            <div className="project-card__top">
                {children}
            </div>
            <div className="project-card__bottom">
                <CardLink link={link} text={text} />
                <CardDescription description={description} />
            </div>
        </div>
    )
}