import { useState } from "react";
import { useAnchor } from "../../../hooks/useAnchor";
import { useClickOutside } from "../../../hooks/useClickOutside";
import logo from '../../../img/logo.svg';
import insta from "../../../img/insta.png";
import be from "../../../img/Be.png";
import { ButtonOpen } from "./ButtonOpen";
import { ButtonClose } from "./ButtonClose";
import { Logo } from "./Logo";
// import { Language } from "./Language";
import { Navigation } from "./Navigation";
import { Phone } from "./Phone";
import { Social } from "./Social";
import "./Burger.scss";

export const Burger = () => {

    const { handleClick } = useAnchor()

    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
        document.body.style.overflow = "hidden"
    }

    const handleClose = () => {
        setOpen(false)
        document.body.style.overflow = "auto"
    }

    const ref = useClickOutside(() => {
        setOpen(false)
    })

    return (
        <div className="burger" ref={ref} >

            {/* {open ? <ButtonClose handleClose={handleClose} /> : <ButtonOpen handleOpen={handleOpen} />} */}

            <ButtonOpen handleOpen={handleOpen} />
            
            {/* {open && ( */}
                <div className={`burger__content ${open ? 'open' : ''}`}>
                    <div className="burger__content-header">
                        <Logo logo={logo} />
                        <ButtonClose handleClose={handleClose} />
                    </div>
                    <div className="burger__content-main">
                        {/* <Language /> */}
                        <Navigation handleClick={handleClick} handleClose={handleClose} />
                        <Phone />
                        <Social insta={insta} be={be} />
                    </div>
                </div>
            {/* )} */}

        </div>
    )
}