import { useState } from "react";
import { Card } from "./card/Card";
import project1 from "../../img/project-1.png";
import project2 from "../../img/project-2.png";
import project3 from "../../img/project-3.png";
import project4 from "../../img/project-4.png";
import project5 from "../../img/project-5.png";
import project6 from "../../img/project-6.png";
import project7 from "../../img/project-7.png";
import project8 from "../../img/project-8.png";
import project9 from "../../img/project-9.png";
import './Projects.scss';

export const Projects = () => {

    const [showMore, setShowMore] = useState(false)

    const handleShowMore = () => setShowMore(!showMore)

    return(
        <section id="projects" className="project">
            <div className="container">
                <h2>Our projects</h2>
                <div className="project__cards">

                    <Card
                        link={'https://kubik-shop.by'}
                        text={'Online store for children\'s Lego construction sets'}
                        description={'E-commerce + Web-design'}
                    >
                        <svg width="1280" height="1280" viewBox="0 0 1280 1280" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <mask id="mask_1" x="0" y="0" width="100%" height="100%">
                                    <path d="M1183.48 88.0846C1279.69 190.185 1281.37 399.956 1279.69 606.014C1279.69 813.929 1274.62 1021.84 1180.11 1136.94C1083.9 1252.03 894.869 1278.02 705.836 1279.88C515.116 1281.74 322.707 1263.17 191.06 1146.22C57.7241 1031.13 -13.1631 819.498 2.02699 624.578C17.2171 429.658 120.172 253.302 251.82 151.202C385.156 49.1007 545.496 23.1114 721.026 8.26034C896.557 -6.59069 1087.28 -12.1598 1183.48 88.0846Z" fill="white" />
                                </mask>
                            </defs>
                            <path d="M1183.48 88.0846C1279.69 190.185 1281.37 399.956 1279.69 606.014C1279.69 813.929 1274.62 1021.84 1180.11 1136.94C1083.9 1252.03 894.869 1278.02 705.836 1279.88C515.116 1281.74 322.707 1263.17 191.06 1146.22C57.7241 1031.13 -13.1631 819.498 2.02699 624.578C17.2171 429.658 120.172 253.302 251.82 151.202C385.156 49.1007 545.496 23.1114 721.026 8.26034C896.557 -6.59069 1087.28 -12.1598 1183.48 88.0846Z" fill="white" />
                            <image xlinkHref={project1} mask="url(#mask_1)" width="100%" height="100%" preserveAspectRatio="none" />
                        </svg>
                    </Card>

                    <Card
                        link={'https://brickandstonesmasonry.com'}
                        text={'Services for the construction and repair of tiles and bricks'}
                        description={'Web-site + Web-design'} 
                    >
                        <svg width="1280" height="1280" viewBox="0 0 1280 1280" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <mask id="mask_2" x="0" y="0" width="100%" height="100%">
                                    <path d="M1065.11 110.481C1187.88 205.684 1251.38 389.744 1272.54 592.844C1293.71 798.06 1274.66 1024.43 1151.9 1149.25C1029.13 1271.96 802.651 1295.23 599.454 1271.96C396.257 1248.69 214.226 1180.99 118.977 1056.17C21.6122 933.46 8.91241 751.516 2.56251 565.341C-3.7874 377.05 -5.90402 184.528 91.4612 89.3242C188.826 -5.87913 381.44 -3.76351 569.821 2.58338C758.201 6.81464 942.349 15.2772 1065.11 110.481Z" fill="white" />
                                </mask>
                            </defs>
                            <path d="M1065.11 110.481C1187.88 205.684 1251.38 389.744 1272.54 592.844C1293.71 798.06 1274.66 1024.43 1151.9 1149.25C1029.13 1271.96 802.651 1295.23 599.454 1271.96C396.257 1248.69 214.226 1180.99 118.977 1056.17C21.6122 933.46 8.91241 751.516 2.56251 565.341C-3.7874 377.05 -5.90402 184.528 91.4612 89.3242C188.826 -5.87913 381.44 -3.76351 569.821 2.58338C758.201 6.81464 942.349 15.2772 1065.11 110.481Z" fill="white" />
                            <image xlinkHref={project2} mask="url(#mask_2)" width="100%" height="100%" preserveAspectRatio="none" />
                        </svg>
                    </Card>

                    <Card
                        link={'https://tupinamba.ge'}
                        text={'Sale and rental of coffee machines'}
                        description={'E-commerce + Web-design'}
                    >
                        <svg width="1280" height="1280" viewBox="0 0 1280 1280" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <mask id="mask_7" x="0" y="0" width="100%" height="100%">
                                    <path d="M1165.53 261.221C1268.72 421.501 1290.45 637.261 1275.96 836.583C1261.48 1035.91 1212.6 1218.79 1107.6 1266.05C1002.61 1313.31 841.491 1227.01 698.477 1206.46C555.464 1185.91 428.743 1233.17 298.401 1185.91C166.249 1138.65 30.4767 994.808 5.13248 822.199C-22.022 649.591 61.2517 446.159 191.593 285.88C321.935 123.545 501.155 6.418 685.805 0.25341C870.456 -5.91118 1060.54 100.942 1165.53 261.221Z" fill="white" />
                                </mask>
                            </defs>
                            <path d="M1165.53 261.221C1268.72 421.501 1290.45 637.261 1275.96 836.583C1261.48 1035.91 1212.6 1218.79 1107.6 1266.05C1002.61 1313.31 841.491 1227.01 698.477 1206.46C555.464 1185.91 428.743 1233.17 298.401 1185.91C166.249 1138.65 30.4767 994.808 5.13248 822.199C-22.022 649.591 61.2517 446.159 191.593 285.88C321.935 123.545 501.155 6.418 685.805 0.25341C870.456 -5.91118 1060.54 100.942 1165.53 261.221Z" fill="white" />
                            <image xlinkHref={project7} mask="url(#mask_7)" width="100%" height="100%" preserveAspectRatio="none" />
                        </svg>
                    </Card>

                    <Card
                        link={'https://bialoklining.pl'}
                        text={'Cleaning services'}
                        description={'Web-site + Branding + Web-design'}
                    >
                        <svg width="1280" height="1280" viewBox="0 0 1280 1280" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <mask id="mask_4" x="0" y="0" width="100%" height="100%">
                                    <path d="M1144.47 168.334C1232.08 276.285 1235.73 435.641 1255.81 615.56C1275.88 793.764 1310.56 992.531 1224.78 1115.9C1137.17 1239.28 925.461 1288.97 726.524 1278.69C525.762 1270.12 334.126 1201.58 202.718 1078.21C69.4847 954.834 -3.51963 774.916 0.130587 600.138C3.78081 425.36 85.9107 254.01 219.144 146.059C350.552 38.1076 533.062 -4.73004 710.098 0.41048C887.134 5.551 1056.87 60.3832 1144.47 168.334Z" fill="white" />
                                </mask>
                            </defs>
                            <path d="M1144.47 168.334C1232.08 276.285 1235.73 435.641 1255.81 615.56C1275.88 793.764 1310.56 992.531 1224.78 1115.9C1137.17 1239.28 925.461 1288.97 726.524 1278.69C525.762 1270.12 334.126 1201.58 202.718 1078.21C69.4847 954.834 -3.51963 774.916 0.130587 600.138C3.78081 425.36 85.9107 254.01 219.144 146.059C350.552 38.1076 533.062 -4.73004 710.098 0.41048C887.134 5.551 1056.87 60.3832 1144.47 168.334Z" fill="white" />
                            <image xlinkHref={project4} mask="url(#mask_4)" width="100%" height="100%" preserveAspectRatio="none" />
                        </svg>
                    </Card>

                    <Card
                        link={'https://dgarebi.com'}
                        text={'Sale and rental service of new and used Spanish Monolithic molding materials'}
                        description={'Web-site + Web-design'}
                    >
                        <svg width="1280" height="1280" viewBox="0 0 1280 1280" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <mask id="mask_5" x="0" y="0" width="100%" height="100%">
                                    <path d="M1073.71 148.455C1204.22 246.718 1285.8 409.871 1279.68 569.317C1273.56 726.908 1179.75 880.791 1047.2 1012.43C916.683 1144.06 747.421 1253.45 555.727 1275.7C361.993 1297.94 145.828 1233.05 56.0984 1101.42C-31.5915 969.784 9.1945 773.258 11.2338 576.733C11.2338 380.207 -25.4736 183.681 62.2163 87.2726C149.906 -9.13621 366.072 -7.28222 563.884 7.5499C761.696 22.382 941.155 52.0463 1073.71 148.455Z" fill="white" />
                                </mask>
                            </defs>
                            <path d="M1073.71 148.455C1204.22 246.718 1285.8 409.871 1279.68 569.317C1273.56 726.908 1179.75 880.791 1047.2 1012.43C916.683 1144.06 747.421 1253.45 555.727 1275.7C361.993 1297.94 145.828 1233.05 56.0984 1101.42C-31.5915 969.784 9.1945 773.258 11.2338 576.733C11.2338 380.207 -25.4736 183.681 62.2163 87.2726C149.906 -9.13621 366.072 -7.28222 563.884 7.5499C761.696 22.382 941.155 52.0463 1073.71 148.455Z" fill="white" />
                            <image xlinkHref={project5} mask="url(#mask_5)" width="100%" height="100%" preserveAspectRatio="none" />
                        </svg>
                    </Card>

                    <Card
                        link={'https://portnixa.by'}
                        text={'Sale of sewing machines and sewing accessories'}
                        description={'E-commerce + Web-design'}
                    >
                        <svg width="1280" height="1280" viewBox="0 0 1280 1280" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <mask id="mask_6" x="0" y="0" width="100%" height="100%">
                                    <path d="M1180.37 102.802C1296.18 223.514 1285.84 469.102 1271.36 698.04C1256.89 926.978 1238.28 1143.43 1122.47 1228.76C1006.66 1314.09 791.582 1270.38 593.051 1255.82C392.453 1243.33 206.33 1257.9 104.997 1172.57C3.66391 1087.23 -12.8803 899.922 7.79995 733.421C28.4802 569.002 88.453 423.315 189.786 302.602C291.12 179.808 435.881 81.989 628.208 32.0389C820.534 -17.9112 1064.56 -19.9924 1180.37 102.802Z" fill="white" />
                                </mask>
                            </defs>
                            <path d="M1180.37 102.802C1296.18 223.514 1285.84 469.102 1271.36 698.04C1256.89 926.978 1238.28 1143.43 1122.47 1228.76C1006.66 1314.09 791.582 1270.38 593.051 1255.82C392.453 1243.33 206.33 1257.9 104.997 1172.57C3.66391 1087.23 -12.8803 899.922 7.79995 733.421C28.4802 569.002 88.453 423.315 189.786 302.602C291.12 179.808 435.881 81.989 628.208 32.0389C820.534 -17.9112 1064.56 -19.9924 1180.37 102.802Z" fill="white" />
                            <image xlinkHref={project6} mask="url(#mask_6)" width="100%" height="100%" preserveAspectRatio="none" />
                        </svg>
                    </Card>

                    {showMore &&
                        <>
                            <Card
                                link={'http://energoideal.by'}
                                text={'Installation and maintenance of thermal energy metering devices'}
                                description={'Web-site + Web-design'}
                            >
                                <svg width="1280" height="1280" viewBox="0 0 1280 1280" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <mask id="mask_3" x="0" y="0" width="100%" height="100%">
                                            <path d="M1199.41 145.606C1292.57 261.745 1287.58 462.82 1269.28 644.828C1250.98 826.836 1221.04 989.776 1126.22 1105.91C1033.06 1220.32 876.693 1287.92 728.641 1279.26C580.59 1270.59 440.855 1185.65 302.785 1071.25C166.378 955.108 31.6338 809.502 5.01778 636.161C-21.5983 462.82 59.9134 261.745 196.321 145.606C334.391 29.4679 527.358 0 720.324 0C913.29 0 1106.26 31.2014 1199.41 145.606Z" fill="white" />
                                        </mask>
                                    </defs>
                                    <path d="M1199.41 145.606C1292.57 261.745 1287.58 462.82 1269.28 644.828C1250.98 826.836 1221.04 989.776 1126.22 1105.91C1033.06 1220.32 876.693 1287.92 728.641 1279.26C580.59 1270.59 440.855 1185.65 302.785 1071.25C166.378 955.108 31.6338 809.502 5.01778 636.161C-21.5983 462.82 59.9134 261.745 196.321 145.606C334.391 29.4679 527.358 0 720.324 0C913.29 0 1106.26 31.2014 1199.41 145.606Z" fill="white" />
                                    <image xlinkHref={project3} mask="url(#mask_3)" width="100%" height="100%" preserveAspectRatio="none" />
                                </svg>
                            </Card>
    
                            <Card
                                link={'https://indolavka.by'}
                                text={'Indian grocery store'}
                                description={'E-commerce + Web-design'}
                            >
                                <svg width="1280" height="1280" viewBox="0 0 1280 1280" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <mask id="mask_8" x="0" y="0" width="100%" height="100%">
                                            <path d="M1116.96 115.786C1200.19 189.253 1196.13 365.576 1222.52 569.186C1248.91 772.796 1303.71 1003.69 1220.49 1133.84C1139.29 1263.98 915.998 1293.37 710.975 1274.48C507.982 1253.48 323.259 1184.21 199.433 1054.07C77.6372 923.93 14.7094 732.914 2.52979 529.303C-11.6797 323.594 24.859 107.389 148.685 33.9217C272.51 -41.6451 481.593 27.6245 672.406 48.6152C863.22 69.606 1033.73 40.2189 1116.96 115.786Z" fill="white" />
                                        </mask>
                                    </defs>
                                    <path d="M1116.96 115.786C1200.19 189.253 1196.13 365.576 1222.52 569.186C1248.91 772.796 1303.71 1003.69 1220.49 1133.84C1139.29 1263.98 915.998 1293.37 710.975 1274.48C507.982 1253.48 323.259 1184.21 199.433 1054.07C77.6372 923.93 14.7094 732.914 2.52979 529.303C-11.6797 323.594 24.859 107.389 148.685 33.9217C272.51 -41.6451 481.593 27.6245 672.406 48.6152C863.22 69.606 1033.73 40.2189 1116.96 115.786Z" fill="white" />
                                    <image xlinkHref={project8} mask="url(#mask_8)" width="100%" height="100%" preserveAspectRatio="none" />
                                </svg>
                            </Card>
    
                            <Card
                                link={'https://bnpa.info'}
                                text={'Belarusian Scientific and Industrial Association'}
                                description={'Web-site + Web-design'}
                            >
                                <svg width="1280" height="1280" viewBox="0 0 1280 1280" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <mask id="mask_9" x="0" y="0" width="100%" height="100%">
                                            <path d="M1161.9 94.6738C1227.8 143.975 1229.93 267.226 1249.06 411.02C1270.32 552.76 1308.59 715.042 1244.81 885.54C1178.91 1056.04 1010.97 1234.75 802.636 1271.73C596.43 1310.76 347.707 1208.05 192.521 1039.6C35.2091 869.106 -28.5659 628.765 11.825 429.508C52.2159 228.197 196.773 67.9692 351.959 18.6685C509.271 -30.6323 675.086 30.9937 823.895 51.5356C970.578 70.0234 1098.13 45.3731 1161.9 94.6738Z" fill="white" />
                                        </mask>
                                    </defs>
                                    <path d="M1161.9 94.6738C1227.8 143.975 1229.93 267.226 1249.06 411.02C1270.32 552.76 1308.59 715.042 1244.81 885.54C1178.91 1056.04 1010.97 1234.75 802.636 1271.73C596.43 1310.76 347.707 1208.05 192.521 1039.6C35.2091 869.106 -28.5659 628.765 11.825 429.508C52.2159 228.197 196.773 67.9692 351.959 18.6685C509.271 -30.6323 675.086 30.9937 823.895 51.5356C970.578 70.0234 1098.13 45.3731 1161.9 94.6738Z" fill="white" />
                                    <image xlinkHref={project9} mask="url(#mask_9)" width="100%" height="100%" preserveAspectRatio="none" />
                                </svg>
                            </Card>
                        </>
                    }

                </div>
                
                <div className="project__button-wrapper">
                    <button className="project__button" onClick={handleShowMore}>
                        <div className="project__button-text">{!showMore ? 'show more' : 'show less'}</div>
                        <svg className={showMore ? 'svg-rotate-180' : ''} width="100" height="90" viewBox="0 0 304 290" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.916351 0.579088L152 108.496L303.083 0.579102L303.083 53.4583L152 161.375L0.916349 53.4583L0.916351 0.579088ZM0.916346 129L152 236.917L303.083 129L303.083 181.879L152 289.796L0.916343 181.879L0.916346 129Z" fill="#4C46AE"/>
                        </svg>
                    </button>
                </div>

            </div>
        </section>
    )
}