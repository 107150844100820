import { by } from "./country-mask-functions/by";
import { ru } from "./country-mask-functions/ru";

export const useMask = (country, countryCode, setInputPhone) => {

    const getInputNumbersValue = (input) => {
        // Return stripped input value — just numbers
        return input ? input.replace(/\D/g, '') : ''
    }

    const onPhoneInput = (e) => {
        let input = e.target.value
        let inputNumbersValue = getInputNumbersValue(input)
        const selectionStart = e.target.selectionStart
        let formattedInputValue = ''

        if (input.length !== selectionStart) {
            // Editing in the middle of input, not last symbol
            if (input && /\D/g.test(input)) {
                // Attempt to input non-numeric symbol
                setInputPhone(input.slice(countryCode.length))
            }
            return
        }

        if (country === 'BY') formattedInputValue = by(inputNumbersValue)
        if (country === 'RU') formattedInputValue = ru(inputNumbersValue)

        setInputPhone(formattedInputValue)
    }

    return { onPhoneInput }
}