import { useTab } from "../../hooks/useTab";
import { useResolution } from "../../hooks/useResolution";
import { useOpen } from "../../hooks/useOpen";
import girl from "../../img/girl-1.webp";
import wave from "../../img/wave.svg";
import waveMobile from "../../img/wave-mobile.svg";
import play from "../../img/play.svg";
import { Modal } from "../../UI/Modal";
import { Form } from "../feedback/Form";
import "./TabsSection.scss";

export const TabsSection = () => {

  const { activeTab, handleTabClick } = useTab()
  const { isMobile } = useResolution()
  const { isOpen, setIsOpen, handleOpen, handleClose } = useOpen()

  return (
    <section id="services" className="tabs">
      <div className="wave-wrapp">
        {isMobile ? <img src={waveMobile} alt="" /> : <img src={wave} alt="" />}
      </div>
      <div className="tabs__container">
        <div className="tabs__wrapp">
          <div className="tabs__wrapp-btn">
            <button
              className={activeTab === 1 ? "tab active-tab" : "tab"}
              onClick={() => handleTabClick(1)}
            >
              Web Sites
            </button>
            <button
              className={activeTab === 2 ? "tab active-tab" : "tab"}
              onClick={() => handleTabClick(2)}
            >
              E-Commerce
            </button>
            <button
              className={activeTab === 3 ? "tab active-tab" : "tab"}
              onClick={() => handleTabClick(3)}
            >
              Web Design
            </button>
            <button
              className={activeTab === 4 ? "tab active-tab" : "tab"}
              onClick={() => handleTabClick(4)}
            >
              Quick solutions
            </button>
          </div>
          <div className="tabs__wrapp-mobile">
            <div className="tab-content-wrapp-mobile">
                <div className="tab-content">
                  {activeTab === 1 && (
                    <div className="tab-content-wrapp">
                      <h2>Design development</h2>
                      <ul className="tab-content-list">
                        <li>
                          <span></span> Development of unique websites for companies
                        </li>
                        <li>
                          <span></span> Development of beautiful landing pages.
                        </li>
                        <li>
                          <span></span> Development of complex corporate websites
                        </li>
                        <li>
                          <span></span> Development on Wordpress, Laravel, Opencart
                        </li>
                        <li>
                          <span></span> Website development using custom solutions
                        </li>
                      </ul>
                    </div>
                  )}
                  {activeTab === 2 && (
                    <div className="tab-content-wrapp">
                      <h2>Design development</h2>
                      <ul className="tab-content-list">
                        <li>
                          <span></span> Development of simple online stores
                        </li>
                        <li>
                          <span></span> Development of functional online stores
                        </li>
                        <li>
                          <span></span> Development on Wordpress, Opencart
                        </li>
                        <li>
                          <span></span> Development of custom solutions
                        </li>
                      </ul>
                    </div>
                  )}
                  {activeTab === 3 && (
                    <div className="tab-content-wrapp">
                      <h2>Design development</h2>
                      <ul className="tab-content-list">
                        <li>
                          <span></span> Creating visual idendity
                        </li>
                        <li>
                          <span></span> Drafting a technical brief
                        </li>
                        <li>
                          <span></span> Research and analyze
                        </li>
                        <li>
                          <span></span> Desining UI
                        </li>
                        <li>
                          <span></span> Desining UX
                        </li>
                      </ul>
                    </div>
                  )}
                  {activeTab === 4 && (
                    <div className="tab-content-wrapp">
                      <h2>Design development</h2>
                      <ul className="tab-content-list">
                        <li>
                          <span></span> Website development using ready-made
                          templates
                        </li>
                        <li>
                          <span></span> Quick start with an inexpensive Wordpress
                          website
                        </li>
                        <li>
                          <span></span> Development time up to 7 days
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                <div className="tabs-form-btn">
                  <button className="tabs-button" onClick={handleOpen}>
                    <img className="tabs-img" src={play} alt="button" />
                    <span className="tabs-text">Discuss the site</span>
                  </button>
                </div>
            </div>
            <div className="tabs-wrapp-img-mobile">
              <img src={girl} alt="girl" width={"100%"} height={"100%"}/>
            </div>
          </div>
        </div>
      </div>
      <div className="tabs-wrapp-img">
        <img src={girl} alt="girl" width={"100%"} height={"100%"} />
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} >
        <Form handleClose={handleClose} />
      </Modal>
    </section>
  );
};
