import "./FailMessage.scss";

export const FailMessage = ({ message }) => {
    return (
        <div className="fail-submit">
            <div className="fail-submit__text">
                <div className="fail-submit__icon-wrapper">
                    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.99977 5.33339V8.00006M7.99977 10.6667H8.00644M6.85977 1.90672L1.2131 11.3334C1.09668 11.535 1.03508 11.7636 1.03443 11.9964C1.03378 12.2292 1.0941 12.4581 1.20939 12.6604C1.32468 12.8627 1.49092 13.0312 1.69157 13.1493C1.89223 13.2674 2.1203 13.3308 2.3531 13.3334H13.6464C13.8792 13.3308 14.1073 13.2674 14.308 13.1493C14.5086 13.0312 14.6749 12.8627 14.7902 12.6604C14.9054 12.4581 14.9658 12.2292 14.9651 11.9964C14.9645 11.7636 14.9029 11.535 14.7864 11.3334L9.13977 1.90672C9.02092 1.71079 8.85358 1.5488 8.6539 1.43638C8.45422 1.32395 8.22893 1.26489 7.99977 1.26489C7.77061 1.26489 7.54532 1.32395 7.34564 1.43638C7.14596 1.5488 6.97862 1.71079 6.85977 1.90672Z" stroke="#F04438" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
                <span>{message}</span>
            </div>
        </div>
    )
}