import React from "react";
import '../Header/Header.scss';
import logo from '../../img/logo.svg';
import circle from '../../img/circle.svg';
import insta from '../../img/insta.png';
import be from '../../img/Be.png';
import { Burger } from "./burger/Burger";
import { useAnchor } from "../../hooks/useAnchor";


export function Header() {

    const { handleClick } = useAnchor()

    return (
        <header className="header">
            <div className="container">
                <div className="header__container">
                    {/* <a href="/" className="header__logo"> */}
                    <div className="header__logo">
                        <img src={logo} alt="" />
                    </div>
                    {/* </a> */}
                    <nav className="header__nav">
                        <ul className="header__list">
                            <li>
                                <a href="#services" onClick={(e) => handleClick(e, "services")}>services</a>
                            </li>
                            <li>
                                <a href="#projects" onClick={(e) => handleClick(e, "projects")}>projects</a>
                            </li>
                            <li>
                                <a href="#about" onClick={(e) => handleClick(e, "about")}>about</a>
                            </li>
                        </ul>
                    </nav>
                    <div className="header__wrapp-right">
                        <a href={'tel:+375293401529'} className="header__link-phone">+375(29)340-15-29</a>
                        {/* <span className="header__btn">
                            <button>en</button> / <button>ru</button>
                        </span> */}
                    </div>
                    <Burger />
                </div>
                <div className="hero">
                    <div className="hero__center">
                        <ul className="hero__social">
                            <li>
                                <a className="hero__social-link" href="https://www.instagram.com/alpairya/" target="__blank">
                                    <img src={insta} alt="Insta" />
                                </a>
                            </li>
                            <li>
                                <a className="hero__social-link" href="https://www.behance.net/alpairya" target="__blank">
                                    <img src={be} alt="Be" />
                                </a>
                            </li>
                        </ul>
                        <div className="hero__branding">
                            <img src={circle} alt="" />
                            <span>Web</span>
                            <span>development</span>
                            <span>studio</span>
                        </div>
                    </div>
                    {/* <span className="hero__descr">Design creation, branding, advertising and internet marketing</span> */}
                    <div className="hero__descr-wrapp">
                        {/* <span className="hero__descr">Web development studio</span> */}
                        <span className="hero__descr">Development of websites of any complexity</span>
                    </div>
                </div>
            </div>
        </header>
    )
}