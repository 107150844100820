

export const Navigation = ({
    handleClick,
    handleClose
}) => {
    return (
        <nav className="burger__nav">
            <ul className="burger__list">
                <li className="burger__list-item">
                    <a href="#services"
                        onClick={(e) => {
                            handleClick(e, "services")
                            handleClose()
                        }}
                    >
                        services
                    </a>
                </li>
                <li className="burger__list-item">
                    <a href="#projects"
                        onClick={(e) => {
                            handleClick(e, "projects")
                            handleClose()
                        }}
                    >
                        projects
                    </a>
                </li>
                <li className="burger__list-item">
                    <a href="#about"
                        onClick={(e) => {
                            handleClick(e, "about")
                            handleClose()
                        }}
                    >
                        about
                    </a>
                </li>
            </ul>
        </nav>
    )
}