import { Dropdown } from '../../UI/Dropdown';
import { Input } from '../../UI/Input';

export const PhoneInputMask = ({
    name,
    className,
    maxLength,
    value,
    onInput,
    onChange,
    codeName,
    options,
    selectedOption,
    onSelect
}) => {

    return (
        <>
            <Dropdown
                name={codeName}
                options={options}
                onSelect={(option) => onSelect(codeName, option)}
                selectedOption={selectedOption}
            />
            <Input
                name={name}
                className={className}
                type={'tel'}
                maxLength={maxLength}
                value={value}
                onInput={onInput}
                onChange={e => onChange(name, e.target.value)}
            />
        </>
    )
}