

export const useAnchor = () => {
    const scrollToElement = (element) => {
        window.scroll({
            behavior: "smooth",
            top: element.offsetTop,
        });
    };

    const handleClick = (e, elementId) => {
        e.preventDefault();
        const element = document.getElementById(elementId);
        scrollToElement(element);
    };

    return { handleClick }
}