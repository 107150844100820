import React from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "./components/Layout";
import { HomePages } from "./pages/HomePages";

function App() {
  return (
    <Routes>
        <Route path='/' element={<Layout/>}>
          <Route index element={<HomePages/>}/>
        </Route>
      </Routes>
  );
}

export default App;
