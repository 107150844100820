

export const Logo = ({
    logo
}) => {
    return (
        <div className="burger__logo">
            <img src={logo} alt="logo" />
        </div>
    )
}