import { useState } from "react"

export const useForm = () => {

    const [status, setStatus] = useState(0)

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        country_code: { value: '+375', label: 'Belarus +375', flag: 'BY', maxLength: '19' },
        phone: '',
    })
    console.log('formData', formData)

    const [inputPhone, setInputPhone] = useState('')

    const [error, setError] = useState({
        name: '',
        email: '',
        phone: '',
    })
    console.log('error', error)

    const countryCodes = [
        { value: '+375', label: 'Belarus +375', flag: 'BY', maxLength: '19' },
        { value: '+7', label: 'Russia +7', flag: 'RU', maxLength: '18' },
    ]

    const handleSelect = (dropdown, option) => {
        setFormData((prev) => ({
            ...prev,
            [dropdown]: {
                value: option.value,
                label: option.label,
                flag: option.flag,
                maxLength: option.maxLength
            },
            phone: ''
        }))
        setInputPhone('')
    }

    const handleChange = (field, value) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value
        }))
        setError((prev) => ({
            ...prev,
            [field]: validateEmpty(value)
        }))
    }

    const handleChangePhone = (field, value) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value
        }))
        // setError((prev) => ({
        //     ...prev,
        //     [field]: validatePhone(value)
        // }))
    }

    const handleChangeEmail = (field, value) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value
        }))
        setError((prev) => ({
            ...prev,
            [field]: validateEmail(value)
        }))
    }

    const validateEmpty = (value) => {
        if (!value) {
            return 'Fill in this field'
        } else {
            return ''
        }
    }

    const validateEmail = (value) => {
        if (!value) {
            return 'Enter an e-mail'
        } else if (!/^\S+@\S+\.\S+$/.test(value)) {
            return 'Enter the correct e-mail'
        }
    
        return ''
    }

    // const validatePhone = (value) => {
    //     console.log('validatePhone', value)
    //     if (!(/^[\d+()\s-]+$/).test(value)) {
    //         return 'Incorrect phone';
    //     } else return ''
    // }

    // const validatePhone = (phone) => {
    //     const phonePattern = /^\d{10}$/
    //     return phonePattern.test(phone)
    // }

    return {
        formData,
        setFormData,
        error,
        setError,
        handleSelect,
        handleChange,
        handleChangePhone,
        handleChangeEmail,
        status,
        setStatus,
        countryCodes,
        inputPhone,
        setInputPhone,
        validateEmpty,
        validateEmail
    }
}