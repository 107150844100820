

export const ru = (inputNumbersValue) => {

    let formattedValue = ''

    if (inputNumbersValue.length > 1) {
        formattedValue += " (" + inputNumbersValue.substring(1, 4)
    }
    if (inputNumbersValue.length >= 5) {
        formattedValue += ") " + inputNumbersValue.substring(4, 7)
    }
    if (inputNumbersValue.length >= 8) {
        formattedValue += "-" + inputNumbersValue.substring(7, 9)
    }
    if (inputNumbersValue.length >= 10) {
        formattedValue += "-" + inputNumbersValue.substring(9, 11)
    }

    return formattedValue
}