import { useOpen } from '../../hooks/useOpen';
import { Modal } from '../../UI/Modal';
import { Form } from '../feedback/Form';
import './About.scss';

export const About = () => {

    const { isOpen, setIsOpen, handleOpen, handleClose } = useOpen()

    return (
        <section id="about" className="about">
            <div className="container">
                <div className="about__container">
                    <h2>About</h2>
                    <span className='about__text'>Alpairya Web Studio is your go-to destination for website development services tailored to your unique needs. Specializing in crafting websites of any complexity, we excel in the creation of dynamic and visually appealing online platforms. Our expertise extends to the development of robust e-commerce solutions, ensuring seamless and efficient online shopping experiences for your customers.</span>
                    <span className='about__text'>At Alpairya, we take pride in offering swift and ready-made web solutions that cater to a diverse range of requirements. Whether you are looking for a custom-built website, an intricate e-commerce platform, or quick and effective web solutions, our dedicated team is committed to delivering excellence.</span>
                    <span className='about__text'>With a focus on innovation and a commitment to quality, Alpairya Web Studio is your trusted partner in bringing your digital vision to life. Let us elevate your online presence and empower your business with cutting-edge web development solutions.</span>
                    <button className='about__button' onClick={handleOpen}>work with us</button>
                </div>
                <Modal isOpen={isOpen} setIsOpen={setIsOpen} >
                    <Form handleClose={handleClose} />
                </Modal>
            </div>
        </section>
    )
}